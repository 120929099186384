import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const goodway_txChip = createIndicator({
    id: 'goodway-txChip',
    displayName: '台指籌碼',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const resolution = this._context.symbol.period;
            const isTX = this._context.symbol.ticker.match(/TX([A,P]M)?-\d+/);
            const isValidResolution = resolution === '1' ||
                resolution === '2' ||
                resolution === '3' ||
                resolution === '5' ||
                resolution === '15' ||
                resolution === '30' ||
                resolution === '60';
            if (!isTX || !isValidResolution)
                return;
            const symbol = ticker + '#goodway_chip960';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const resolution = this._context.symbol.period;
            const isTX = this._context.symbol.ticker.match(/TX([A,P]M)?-\d+/);
            const isValidResolution = resolution === '1' ||
                resolution === '2' ||
                resolution === '3' ||
                resolution === '5' ||
                resolution === '15' ||
                resolution === '30' ||
                resolution === '60';
            if (!isTX || !isValidResolution)
                return;
            this._context.select_sym(1);
            const foreign = this.PineJS.Std.open(this._context);
            const retail = this.PineJS.Std.low(this._context);
            const marketMaker = this.PineJS.Std.high(this._context);
            return [
                foreign,
                retail,
                marketMaker,
                this.PineJS.Std.sma(this._context.new_var(marketMaker), 23, context),
            ];
        },
    },
    metainfo: {
        is_price_study: false,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        inputs: [],
        plots: [
            { id: 'foreign', type: 'line' },
            { id: 'retail', type: 'line' },
            { id: 'marketMaker', type: 'line' },
            { id: 'marketMakerAvg', type: 'line' },
        ],
        styles: {
            foreign: {
                title: '外資',
                histogramBase: 0,
                joinPoints: false,
            },
            retail: {
                title: '散戶',
                histogramBase: 0,
                joinPoints: false,
            },
            marketMaker: {
                title: '作手',
                histogramBase: 0,
                joinPoints: false,
            },
            marketMakerAvg: {
                title: '作手Avg',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        defaults: {
            styles: {
                foreign: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    visible: true,
                    color: '#7E8484',
                },
                retail: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    visible: true,
                    color: '#E92410',
                },
                marketMaker: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 40,
                    visible: true,
                    color: '#83A3EE',
                },
                marketMakerAvg: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 40,
                    visible: true,
                    color: '#ffff00',
                },
            },
            precision: 2,
            inputs: {},
        },
    },
});
