import { css } from '@emotion/react';
import { flatten } from 'lodash';
import { memo, useState } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { OsFuturesListTile } from '~/modules/monitors/OsFuturesListTile';
import { SignalrCandleTooltipQuote } from '~/modules/monitors/SignalrCandleTooltipQuote';
import { useWeightedStocksPoolState } from '~/modules/monitors/useWeightedStocksPoolState';
import { CandleBarChart } from '~/modules/SDK/socket/CandleBarChart';
import { useOsFuturesList } from '~/modules/SDK/Symbol/useCommonSymbolList';
export const OsFuturesMonitors = memo(function OsFuturesMonitors() {
    const [currentSymbolData, setCurrentSymbolData] = useState('TSEA');
    const osFutures = useOsFuturesList();
    const symbols = flatten(Object.entries(osFutures ?? {}).map(([k, v]) => v));
    return (<div css={css `
        width: 100%;
        ${scrollbar2Css};
      `}>
      <useWeightedStocksPoolState.Provider>
        <div>
          {currentSymbolData && <SignalrCandleTooltipQuote symbol={currentSymbolData ?? ''}/>}
        </div>
        <div css={css `
            width: 100%;
            height: 264px;
            padding: 4px;
          `}>
          <CandleBarChart hoverDelegate={setCurrentSymbolData} symbols={symbols}/>
        </div>
        <div css={css `
            width: 100%;
            width: 100%;
            height: 100%;
            ${flex.v.default};
            overflow-y: hidden;
            overflow-x: auto;
            &::-webkit-scrollbar {
              height: 5px;
              background-color: #555;
            }
            &::-webkit-scrollbar-thumb {
              height: 5px;
              border-radius: 5px;
              background-color: #ccc;
            }
          `}>
          <OsFuturesListTile />
        </div>
      </useWeightedStocksPoolState.Provider>
    </div>);
});
