import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { flex } from '~/modules/AppLayout/FlexGridCss';
const PositionItem = memo(function PositionItem(props) {
    const theme = useThemeStore(t => t.theme);
    const itemName = props.name;
    const itemValue = props.value;
    const itemPosition = props.position;
    console.log(itemValue);
    /** 專門顯示持倉部位的item 會顯示`+1@entryPrice` `-1@entryPrice` */
    const displayEntryQty = () => {
        if (itemName.includes('成本')) {
            if (itemPosition === 1) {
                return '+1 @';
            }
            else if (itemPosition === -1) {
                return '-1 @';
            }
        }
        else {
            return null;
        }
    };
    /** 用於未平倉損益 損益前會顯示`正負符號` */
    const openProfitSymbol = () => {
        if (itemName.includes('損益')) {
            if (itemValue > 0) {
                return '+';
            }
            else
                null;
        }
    };
    /** 用於區間範圍停損或停利 會顯示`12000~12005` */
    const displayMultipleValue = () => {
        if (Array.isArray(itemValue)) {
            return itemValue[0] + '~' + itemValue[1];
        }
        else
            return null;
    };
    /** 一般數值 */
    const defaultValue = () => {
        return displayMultipleValue() || itemValue;
    };
    /** 文字顏色 */
    const textColor = () => {
        if (itemValue > 0)
            return '#ff0000';
        else if (itemValue < 0)
            return '#00ff00';
    };
    /** 背景顏色 */
    const backgroundColor = () => {
        if (itemPosition === 1)
            return '#aa0000';
        else if (itemPosition === -1)
            return '#00aa00';
    };
    const defaultValueState = !itemName.includes('損益') && !itemName.includes('成本');
    return (<Styleds.Item>
        <Styleds.Name theme={theme} className={PositionInfomationClasses.ItemTitle}>
          {itemName}
        </Styleds.Name>

        {itemName.includes('成本') && (<Styleds.Value theme={theme} className={isNaN(itemValue) ? PositionInfomationClasses.ItemValue : ''} profit={true} isEmpty={isNaN(itemValue)} css={css `
              background-color: ${backgroundColor()};
            `}>
            {displayEntryQty()}
            {itemValue}
          </Styleds.Value>)}

        {itemName.includes('損益') && (<Styleds.Value theme={theme} className={PositionInfomationClasses.ItemValue} css={css `
              color: ${textColor()};
            `}>
            {openProfitSymbol()}
            {itemValue}
          </Styleds.Value>)}

        {defaultValueState && (<Styleds.Value theme={theme} className={PositionInfomationClasses.ItemValue}>
            {defaultValue()}
          </Styleds.Value>)}
      </Styleds.Item>);
});
export const PositionInfomation = memo(function PositionInfomation(props) {
    const options = props.PositionOptions;
    const theme = useThemeStore(t => t.theme);
    return (<Styleds.Container>
      {options?.map(datum => {
            return (<Styleds.Item key={datum.displayName}>
            <PositionItem name={datum.displayName} value={datum.value} position={datum.marketPosition}/>
          </Styleds.Item>);
        })}
    </Styleds.Container>);
});
const Styleds = {
    Container: styled.div `
    ${flex.v.crossCenter}
    width: 100%;
    height: 100%;
    padding: 5px;
    gap: 2.5px;
  `,
    Item: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    gap: 5px;
  `,
    Name: styled.div `
    ${flex.v.allCenter};
    width: 50%;
    height: 30px;
    border-radius: 5px;

    ${options => {
        const darkTheme = options.theme === 'dark' &&
            css `
          background-color: #333333;
        `;
        const lightTheme = options.theme === 'light' &&
            css `
          background-color: #dddddd;
        `;
        return css([darkTheme, lightTheme]);
    }}
  `,
    Value: styled.div `
    ${flex.v.allCenter};
    width: 50%;
    height: 30px;
    border-radius: 5px;
    ${options => {
        const darkTheme = options.theme === 'dark' &&
            css `
          border: 1px solid #555555;
          color: #eeeeee;
        `;
        const lightTheme = options.theme === 'light' &&
            css `
          border: 1px solid #555555;
          color: ${options.profit === true && options.isEmpty === false ? ' #eeeeee' : '#111111'};
        `;
        return css([darkTheme, lightTheme]);
    }}
  `,
};
export const PositionInfomationClasses = {
    ItemTitle: `${PositionInfomation.name}-ItemTitle`,
    ItemValue: `${PositionInfomation.name}-ItemValue`,
};
