import useSWR from 'swr-0-5-6';
import urlcat from 'urlcat';
import { apirc } from '~/configs/apirc';
const fetcher = async (url) => {
    const res = await fetch(url);
    if (res.status >= 400)
        throw new Error();
    return res.json();
};
/** 股期商品近遠月分數 */
export const useDaddy960Tradeinfo = (date) => {
    const url = urlcat(apirc.unclassified.daddy960TradeinfoURL.toString(), { date });
    const res = useSWR(url, fetcher, {
        revalidateOnFocus: false,
        refreshInterval: 15000,
    });
    return {
        data: res.data ? res.data : { tx_bvav: 0, mtx_bvav: 0, mtx_tbta: 0 },
        isLoading: !res.data && !res.error,
    };
};
