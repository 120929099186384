import { css } from '@emotion/react';
import { memo, useState } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { SignalrCandleTooltipQuote } from '~/modules/monitors/SignalrCandleTooltipQuote';
import { StockCandleBarChart } from '~/modules/monitors/StockCandleBarChart';
import { StockListTile } from '~/modules/monitors/StockListTile';
import { useWeightedStocksPoolState } from '~/modules/monitors/useWeightedStocksPoolState';
export const StockMonitors = memo(function StockMonitors() {
    const [currentSymbolData, setCurrentSymbolData] = useState('TSEA');
    return (<div css={css `
        width: 100%;
        ${scrollbar2Css};
      `}>
      <useWeightedStocksPoolState.Provider>
        <div>
          {currentSymbolData && <SignalrCandleTooltipQuote symbol={currentSymbolData ?? ''}/>}
        </div>
        <div css={css `
            width: 100%;
            height: 264px;
            padding: 4px;
          `}>
          <StockCandleBarChart hoverDelegate={setCurrentSymbolData}/>
        </div>
        <div css={css `
            width: 100%;
          `}>
          <StockListTile />
        </div>
      </useWeightedStocksPoolState.Provider>
    </div>);
});
